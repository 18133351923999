import * as React from "react"
import { Fragment } from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Popover, Transition } from '@headlessui/react'
import { Bars2Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css'

SwiperCore.use([Autoplay]);

const navigation = [
	{ name: 'BERANDA', href: '/#home' },
	{ name: 'LAYANAN', href: '/#layanan' },
	{ name: 'TESTIMONI', href: '/#testimoni' },
	{ name: 'PRODUK', href: '/#produk' },
	{ name: 'HUBUNGI', href: '/#hubungi' },
  ]

const IndexPage = () => {
	function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-11040385823/dHN8CMXNyIQYEJ_Wu5Ap"]})
	}
	function handleClickCall() {
		window.gtag("event", "conversion", { send_to: ["AW-11040385823/wj28CMjNyIQYEJ_Wu5Ap"]})
	}

	const { placeholderImage } = useStaticQuery(
		graphql`
		  query {
			placeholderImage: file(relativePath: { eq: "hero-autos-aki.png" }) {
			  childImageSharp {
				gatsbyImageData(
				  width: 1280
				  placeholder: BLURRED
				  formats: [AUTO, WEBP, AVIF]
				)
			  }
			}
		  }
		`
	  )
	const image = getImage(placeholderImage)
	const bgImage = convertToBgImage(image)

  	return (
		<div>
			<div className="">
				<div className="z-50 bg-white fixed top-0 left-0 right-0">
					<Popover>
						{({ open }) => (
						<>
							<div className="flex flex-row justify-between py-4 lg:py-2 px-4 sm:px-6 md:px-0 max-w-7xl mx-auto">
								<div>
									<Link to="/">
										<span className="sr-only">Autos-Aki</span>
										<StaticImage src="../images/autos-aki-logo.png" alt="logo"  height={58} />
									</Link>
								</div>
								<nav
									className="flex items-center justify-between lg:justify-start"
									aria-label="Global"
								>
									<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
									<div className="flex items-center justify-between w-full md:w-auto">
										<div className="-mr-2 flex items-center md:hidden">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-[#000086] hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
											<span className="sr-only">Open main menu</span>
											<Bars2Icon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
										</div>
									</div>
									</div>
									<div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
									{navigation.map((item) => (
										<AnchorLink key={item.name} to={item.href} className="font-semibold text-[#000086] hover:text-main">
										{item.name}
										</AnchorLink>
									))}
									</div>
								</nav>
							</div>

							<Transition
							show={open}
							as={Fragment}
							enter="duration-150 ease-out"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="duration-100 ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
							>
								<Popover.Panel
									focus
									static
									className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
								>
									<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div className="px-5 pt-4 flex items-center justify-between pb-[18px] border-b">
										<div>
											<StaticImage src="../images/autos-aki-logo.png" alt="logo" height={48} />
										</div>
										<div className="-mr-2">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-[#000086] hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
											<span className="sr-only">Close main menu</span>
											<XMarkIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
										</div>
									</div>
									<div className="px-2 pt-2 pb-3 space-y-1">
										{navigation.map((item) => (
										<AnchorLink
											key={item.name}
											to={item.href}
											className="text-center block px-3 py-2 rounded-md text-base font-medium text-[#000086] hover:text-white hover:bg-[#000086]"
										>
											{item.name}
										</AnchorLink>
										))}
									</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
						)}
					</Popover>
				</div>
			</div>
			<BackgroundImage
				Tag="section"
				{...bgImage}
				>
				<div className='container mx-auto h-full lg:h-[90vh] text-white'>
					<div className='flex flex-col px-6 pt-32 pb-8 lg:pt-8 lg:pb-0 lg:grid lg:grid-cols-2 lg:h-full lg:content-center lg:mx-auto max-w-7xl'>
						<div className='flex items-center justify-center order-2 lg:order-1 mt-12 md:mt-0 mb-8 md:mb-0'>
							<div className='flex flex-col items-start'>
								<h1 className='text-2xl md:text-4xl font-semibold mb-4'>Sedia Aki Berkualitas dengan Layanan Antar dan Pasang Aki</h1>
								<p className="mb-8">Cara mudah pesan aki ketika mogok di rumah maupun di jalan<br/>Kami antar dan pasangkan aki ke lokasi anda dari beberapa cabang kami yang terdekat<br/>Gratis layanan antar dan pasang dengan diberikan aki yang sesuai kebutuhan kendaraan anda</p>
								<div className='grid grid-cols-1 lg:grid-cols-2 gap-3 contact-button mx-auto lg:mx-0'>
									<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281210443383&text=Halo%2C autos-aki.id" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-600 hover:bg-green-500 text-white font-bold py-3 px-4 rounded inline-flex items-center shadow-md">
										<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
										<span>Whatsapp Kami</span>
									</OutboundLink>
									<OutboundLink onClick={handleClickCall} href='tel:+6281210443383' className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-[#000086] text-white font-bold py-3 px-4 rounded inline-flex items-center mx-auto shadow-md">
									<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
										<span>Telepon Kami</span>
									</OutboundLink>
								</div>
							</div>
						</div>
						<div className='flex items-center justify-center order-1 lg:order-2 z-0 w-4/5 lg:w-full mx-auto'>
							<StaticImage src="../images/aki-hero.png" alt="aki"/>
						</div>
					</div>
				</div>
			</BackgroundImage>

			<div className="bg-slate-200 py-10">
				<div className="container mx-auto max-w-7xl px-6 lg:px-0">
					<div className="grid grid-cols-1 text-center mb-10 text-3xl font-semibold">
						<h2>TENTANG KAMI</h2>
						<span className="border-[#000086] border-b-4 pb-6 w-1/12 mx-auto"></span>
					</div>
					<div className='flex flex-row'>
						<div className='flex justify-center'>
							<p className='text-md md:text-lg text-center'><strong>Autos-Aki.id</strong> adalah toko aki mobil dengan kualitas terbaik untuk kebutuhan kendaraan anda. Produk-produk yang kami jual adalah aki dijamin original, berkualitas dan bergaransi. Produk aki yang disediakan oleh <strong>Autos-Aki.id</strong> akan disesuaikan dengan kebutuhan kendaraan anda, adapun brand seperti aki <strong>GS Astra</strong>, <strong>Yuasa</strong>, <strong>Incoe</strong>, <strong>Solite</strong>, <strong>Amaron</strong> dll. Demi menjaga kepercayaan konsumen, kami berkomitmen hanya menjual produk Original dan berkualitas.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="px-6 lg:px-0 py-10">
				<div className="container mx-auto max-w-7xl">
					<div className="grid grid-cols-1 text-center mb-10 text-3xl font-semibold">
						<h2>LAYANAN KAMI</h2>
						<span className="border-[#000086] border-b-4 pb-6 w-1/12 mx-auto"></span>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
						<div className="service-item">
							<div className="service-image relative z-0">
								<StaticImage className="z-0" src="../images/free-delivery.png" alt="aki"/>
							</div>
							<div className="service-content -mt-[100px] relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Gratis Jasa Antar & Pasang</h3>
								<p className="text-white"><strong>Autos-Aki.id</strong> melayani jasa antar dan pasang ke lokasi seperti antar dan pasang di rumah ataupun ketika sedang mogok di jalan</p>
							</div>
						</div>

						<div className="service-item">
						<div className="service-image relative z-0">
								<StaticImage className="z-0" src="../images/trade-in.png" alt="aki"/>
							</div>
							<div className="service-content -mt-[100px] relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Trade In / Tukar Tambah</h3>
								<p className="text-white">Menerima trade in / tukar tambah aki lama anda dengan aki baru sehingga dapat mengurangi harga aki yang di beli</p>
							</div>
						</div>

						<div className="service-item">
							<div className="service-image relative z-0">
								<StaticImage className="z-0" src="../images/stock-warranty.png" alt="aki"/>
							</div>
							<div className="service-content -mt-[100px] relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Stock Baru & Bergaransi</h3>
								<p className="text-white">Kami menjamin aki yang kami berikan dipastikan aki baru, sehingga kami berikan garansi untuk aki yang anda pakai</p>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="bg-slate-200 px-6 lg:px-0 py-10">
				<div className="container mx-auto max-w-7xl">
					<div className="grid grid-cols-1 text-center mb-10 text-3xl font-semibold">
						<h2>TESTIMONI</h2>
						<span className="border-[#000086] border-b-4 pb-6 w-1/12 mx-auto"></span>
					</div>
					<div className="text-center">
						<Swiper
						spaceBetween={50}
						slidesPerView={1}
						centeredSlides={true} 
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
						>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/rizky.png" alt="rizky" className='w-32 m-auto' loading='{ lazy }'/>
									<h4 className='text-xl mt-2'><strong>Rizky</strong></h4>
									<h5 className='text-sm my-1'>Staff</h5>
									<p className="text-md">Aki nya benar-benar tahan lama dan beli yang kedua kalinya dengan merk yang sama</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/rusli.png" alt="rusli" className='w-32 m-auto' loading='{ lazy }'/>
									<h4 className='text-xl mt-2'><strong>Rusli</strong></h4>
									<h5 className='text-sm my-1'>Driver</h5>
									<p className="text-md">Respon sangan cepat ketika saya mogok di jalan dan mekaniknya juga cepat datang, saya rekomendasikan untuk teman-teman driver lain</p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-2/3 lg:w-3/6 mx-auto'>
									<StaticImage layout={'fixed'} height={128} width={128} src="../images/fitri.png" alt="fitri" className='w-32 m-auto' loading='{ lazy }'/>
									<h4 className='text-xl mt-2'><strong>Fitri</strong></h4>
									<h5 className='text-sm my-1'>Wiraswasta</h5>
									<p className="text-md">Karena Puas dengan pelayanannya, tetangga saya juga memakai jasa <strong>Autos-Aki.id</strong> setelah saya sarankan untuk mengganti aki mobilnya</p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</div>

			<div className="px-6 lg:px-0 py-10">
				<div className="container mx-auto max-w-7xl">
					<div className="grid grid-cols-1 text-center mb-10 text-3xl font-semibold">
						<h2>PRODUK</h2>
						<span className="border-[#000086] border-b-4 pb-6 w-1/12 mx-auto"></span>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
						<div className="service-item">
							<div className="service-image relative z-0">
								<StaticImage className="z-0 w-full" src="../images/aki-basah.png" alt="aki"/>
							</div>
							<div className="service-content relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Aki Basah (Conventional Battery)</h3>
							</div>
						</div>

						<div className="service-item">
						<div className="service-image relative z-0">
								<StaticImage className="z-0 w-full" src="../images/aki-hybrid.png" alt="aki"/>
							</div>
							<div className="service-content relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Aki Hybrid</h3>
							</div>
						</div>

						<div className="service-item">
							<div className="service-image relative z-0">
								<StaticImage className="z-0 w-full" src="../images/aki-mf.png" alt="aki"/>
							</div>
							<div className="service-content relative z-40 p-5 transition-color duration-500 ease-in-out motion-reduce:transition-none motion-reduce:transform-nonetransition-color motion-reduce:transform-none bg-[#120068] hover:bg-[#000086]">
								<h3 className="text-lg font-semibold text-white pb-2">Aki Kering (Maintenance Free Battery)</h3>
							</div>
						</div>
					</div>
					<div className="mt-10">
						<Swiper
							slidesPerView={2}
							spaceBetween={50}
							autoplay={{
								"delay": 2000,
								"disableOnInteraction": false
							}}
							breakpoints={{
								1024: {
									slidesPerView: 4,
								},
							}}
							>
								<SwiperSlide>
									<StaticImage src="../images/gsastra.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/amaron.png" alt="amaron" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fasterr.png" alt="faster" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/furukawa.png" alt="furukawa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoe.png" alt="incoe" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/solite.png" alt="solite" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa.png" alt="yuasa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</div>

			<div className="bg-slate-200 px-6 lg:px-0 py-10">
				<div className="container mx-auto max-w-7xl">
					<div className="grid grid-cols-1 text-center mb-10 text-3xl font-semibold">
						<h2>HUBUNGI KAMI</h2>
						<span className="border-[#000086] border-b-4 pb-6 w-1/12 mx-auto"></span>
					</div>
					<div className="text-center">
						<p>Info Lebih Lanjut Silahkan Hubungi Kami!</p>
						<div className='grid grid-cols-1 contact-button mx-auto'>
							<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281210443383&text=Halo%2C autos-aki.id" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-600 hover:bg-green-500 text-white font-bold py-3 px-4 rounded items-center  mx-auto shadow-md mt-4">
								<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
								<span>Whatsapp Kami</span>
							</OutboundLink>
							<OutboundLink onClick={handleClickCall} href='tel:+6281210443383' className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-[#000086] text-white font-bold py-3 px-4 rounded items-center mx-auto shadow-md mt-4">
							<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
								<span>Telepon Kami</span>
							</OutboundLink>
						</div>
						<p className="mt-4">Alamat Email Kami : <strong>autosaki.id@gmail.com</strong></p>
					</div>
				</div>
			</div>
			<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281210443383&text=Halo%2C autos-aki.id" className="wa-float" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
			</OutboundLink>
			<OutboundLink onClick={handleClickCall} href="tel:+6281210443383" className="call-float" rel="noreferrer">
				<FontAwesomeIcon className='call-my-float' icon={faPhone} />
			</OutboundLink>

			<div className="bg-[#120068] text-center text-white p-4 font-medium">
				
			</div>
			
		</div>
  )
}

export default IndexPage

export const Head = () => <title>Autos-Aki.id</title>
